<div class="card portfolio-card balance-box">
  <div class="card-body">
    <div class="row">
      <div class="media faq-widgets">
        <i class="icofont icofont-wallet portfolio-icon m-auto"></i>
        <div class="media-body">
          <h3>{{"My Portfolio" | translate}}</h3>
          <div class="portfolio-balance d-flex align-items-center f-light">
            {{ totalBalance | number:'1.2-2' }}
            <div ngbDropdown class="d-inline-block dropdown-currency">
              <button class="btn dropdown-toggle btn-light" type="button"
                data-bs-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false" ngbDropdownToggle
                style="background-color: transparent !important;">
                <div class="selectedCurrency">{{ selectedCurrency }}</div>
              </button>
              <div class="dropdown-menu dropdown-size" ngbDropdownMenu style="z-index: 11 !important;">
                <a class="dropdown-item" ngbDropdownItem href="javascript:void(0)"
                  *ngFor="let curr of currency" (click)="handleSelectCurrency(curr.name)"
                  style="opacity: unset; display: flex;">
                  <img class="m-r-20" [src]="curr.flag_icon" style="width: 40px; height: auto;">
                  <span>{{ curr.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
