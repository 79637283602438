<div class="media profile-media">
  <div class="my-account-icon">
    <!-- <i class="icofont icofont-wallet icon-size-wallet"></i> -->
    <iframe width="30" height="30" [scrolling]="'no'" [frameBorder]="0" [src]="iframeSrc | safe" allowfullscreen
    style="float: left;"></iframe>
  </div>
  <!-- <app-identicon></app-identicon> -->
  <div class="media-body" style="margin-left: 5px;">
    <span>{{wallet_info.metaName}}</span>
    <p class="mb-0 font-roboto">{{wallet_info.address_display}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>

<ul class="profile-dropdown onhover-show-div">
  <li style="display: flex; justify-content: space-between;">
    <span>{{'Balance' | translate}}:</span>
    <span> {{wallet_balance}} {{ tokenSymbol }}</span>
  </li>
  <li style="display: flex; justify-content: space-between;">
    <span>{{'Wallet' | translate}}:</span>
    <span style="display: flex; gap: 0.5rem;"> {{wallet_info.address_display}} <a href="javascript:void(0);" (click)="copyInputMessage(wallet_info.address)"><i class="fa fa-copy"></i></a></span>
  </li>
  <li>
    <a class="d-flex" href="https://dashboard.xgame.live/wallet">
      <app-feather-icons class="d-flex" [icon]="'settings'"></app-feather-icons>
      <span>{{ 'Dashboard' | translate}}</span>
    </a>
  </li>
  <li>
    <a class="d-flex" href="javascript:void(0);" (click)="logout()">
      <app-feather-icons class="d-flex" [icon]="'log-in'"></app-feather-icons>
      <span>{{ 'Log Out' | translate}}</span>
    </a>
  </li>
</ul>
