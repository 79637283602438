<!-- footer start-->

<div class="container-fluid p-t-20">
  <div class="row">
    <div class="col-12 p-b-20">
      <ul class="main_menu d-flex">
        <li *ngFor="let menu of other_menuItems; let first = first"
            [class.active]="isActive(menu.path)">
            <a [routerLink]="menu.path" class="footer-link" *ngIf="menu.title=='Marketplace'" class="text-secondary f-w-900">{{ menu.title | translate}}</a>
            <a [href]="menu.path" class="footer-link" *ngIf="menu.title!='Marketplace'" class="text-secondary f-w-900">{{ menu.title | translate}}</a>
        </li>
      </ul>
      <ul class="main_menu d-flex">
        <li *ngFor="let menu of footer_menuItems; let first = first"
            [class.active]="isActive(menu.path)">
          <a [routerLink]="menu.path" class="footer-link" *ngIf="menu.title=='Marketplace'" class="text-secondary f-w-900">{{ menu.title | translate}}</a>
          <a [href]="menu.path" class="footer-link" *ngIf="menu.title!='Marketplace'" class="text-secondary f-w-900">{{ menu.title | translate}}</a>
        </li>
      </ul>
    </div>
    <div class="col-12">
      <div>
        <a  href="https://www.xgame.live/">
          <img
            class="img-fluid for-light image-logo p-b-10"
            src="./../../../../assets/images/logo/logo.webp"
            alt=""
          />
          <img
            class="img-fluid for-dark image-logo p-b-10"
            src="./../../../../assets/images/logo/logo_dark.webp"
            alt=""
          />
        </a>

        <div>
          <p class="m-b-10">Varšavská 715/36, Vinohrady, 120 00 Prague 2</p>
          <p class="m-b-10">+420 772 438 450</p>
        </div>
        <p class="m-b-10">        
          <a [href]="'mailto:' + email">{{
          email
        }}</a> 
        </p>
      </div>
      <div class="d-flex flex-column flex-wrap justify-content-center">
        <p class="m-b-0 text-center">{{'STAY CONNECTED' | translate}}!</p>
        <div class="social-media p-b-10">
          <a [href]="social_media.path" target="_blank" *ngFor="let social_media of social_media_menuItems">
            <button class="btn btn-pill btn-light button-back-wallet d-flex" placement="top" ngbTooltip="{{ social_media.title | translate }}">
              <i
                class="icofont icofont-{{ social_media.icon }} f-20"
                *ngIf="social_media.title != 'Discord'"
              ></i>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                class="bi bi-discord"
                viewBox="0 0 16 16"
                *ngIf="social_media.title == 'Discord'"
              >
                <path
                  d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"
                />
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copyright">
  <div class="container-lg p-t-20 p-b-20">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 ">
        <ul class="d-flex">
          <li         
          *ngFor="let menu of legal_menuItems; let first = first"
          [class.active]="isActive(menu.path)"
          >
            <a [href]="menu.path" class="footer-link text-secondary f-w-900">{{ menu.title | translate}}</a>
          </li>
        </ul>
        <p class="f-14 m-b-0" style="text-align: right;">
          {{'Copyright' | translate}} {{ today | date : "y" }} © XGame by NexGen
        </p>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container-lg p-t-20">
  <div class="row">
    <div class="col-xxl-12">
      
      <div class="footer_section d-flex">
        <div>
          <ul class="main_menu d-flex">
            <li 
            *ngFor="let menu of other_menuItems; let first = first"
            (click)="redirectTo(menu.path)">
              <a href="javascript:void(0)" class="footer-link">{{ menu.title | translate}}</a>
            </li>
          </ul>
          <div>
            <a href="https://www.xgame.live/">
              <img
                class="img-fluid for-light image-logo"
                src="./../../../../assets/images/logo/logo.webp"
                alt=""
              />
              <img
                class="img-fluid for-dark image-logo"
                src="./../../../../assets/images/logo/logo_dark.webp"
                alt=""
              />
            </a>
    
            <div>
              <div>Varšavská 715/36, Vinohrady, 120 00 Prague 2</div>
              <div>+420 772 438 450</div>
            </div>
            <a [href]="'mailto:' + appSettings.Email">{{
              appSettings.Email
            }}</a> 
          </div>
        </div>
        <div>
          <ul class="main_menu d-flex">
            <li 
            *ngFor="let menu of footer_menuItems; let first = first"
            (click)="redirectTo(menu.path)">
              <a href="javascript:void(0)" class="footer-link">{{ menu.title | translate}}</a>
            </li>
          </ul>
          <div class="social-media">
            <a [href]="social_media.path" target="_blank" *ngFor="let social_media of social_media_menuItems">
              <button class="btn btn-pill btn-light button-back-wallet d-flex" placement="top" ngbTooltip="{{ social_media.title | translate }}">
                <i
                  class="icofont icofont-{{ social_media.icon }} f-20"
                  *ngIf="social_media.title != 'Discord'"
                ></i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  class="bi bi-discord"
                  viewBox="0 0 16 16"
                  *ngIf="social_media.title == 'Discord'"
                >
                  <path
                    d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"
                  />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="copyright">
  <div class="container-lg p-t-20">
    <div class="row">
      <div class="col-xxl-12 col-xl-12 col-lg-12 ">
        <div class="d-flex footer_section">
          <ul class="d-flex">
            <li         
            *ngFor="let menu of legal_menuItems; let first = first"
            (click)="redirectTo(menu.path)"
            >
              <a href="javascript:void(0)" class="footer-link">{{ menu.title | translate}}</a>
            </li>
          </ul>
          <p class="f-14 m-b-0" style="text-align: right;">
            {{'Copyright' | translate}} {{ today | date : "y" }} © XGame by NexGen
          </p>
        </div>
      </div>
    </div>
  </div>
</div>  -->